<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link>
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">Manage DNS</h1>
                <!-- <p class="text-caption text-center">DNS</p> -->
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 px-10 mb-8">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <!-- TODO: query dns records for this domain only; allow adding new records, editing existing records, deleting records -->
                    <v-card tile elevation="4" class="pa-0" v-if="dnsRecordList">
                        <v-toolbar short flat color="white">
                            <v-toolbar-title class="purple--text"><!-- DNS records for -->{{ this.$route.params.domain }}</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon color="purple" @click="createNewRecordDialog = true">
                                <font-awesome-icon :icon="['fas', 'plus']" style="font-size: 20px;" fixed-width/>
                            </v-btn>
                            <!-- <v-menu offset-y left>
                                <template v-slot:activator="{ on }">
                                    <v-btn icon color="purple" v-on="on">
                                        <font-awesome-icon :icon="['far', 'ellipsis-v']" style="font-size: 20px;" fixed-width/>
                                    </v-btn>
                                </template>
                                <v-list class="ma-0 pa-0">
                                    <v-list-item-group>
                                    <v-list-item :to="{ name: 'service-admin-domain-settings' }">
                                        <v-list-item-title>Settings</v-list-item-title>
                                    </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-menu> -->
                        </v-toolbar>
                        <!-- <v-divider></v-divider> -->
                        <!-- <v-list dense nav>
                            <v-list-item v-if="dnsRecordList.length === 0">No domains yet.</v-list-item>
                            <v-list-item v-for="item in dnsRecordList" v-bind:key="item.id" class="my-2" @click="onClickItem(item.id)">
                                <v-list-item-content class="text-start">
                                    TBD record goes here {{ JSON.stringify(item) }}
                                </v-list-item-content>
                            </v-list-item>
                        </v-list> -->
                        <v-simple-table>
                            <template #default>
                                <thead>
                                    <tr>
                                        <th class="text-left">
                                        Type
                                        </th>
                                        <th class="text-left">
                                        Key
                                        </th>
                                        <th class="text-left">
                                        Value
                                        </th>
                                        <th class="text-left">
                                        Refresh
                                        </th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(item, idx) in dnsRecordList" :key="idx">
                                        <td class="text-left">
                                            {{ item.type }}
                                        </td>
                                        <td class="text-left">
                                            {{ item.key }}
                                        </td>
                                        <td class="text-left">
                                            <template v-if="item.priority">
                                                <v-tooltip top>
                                                    <template #activator="{ on, attrs }">
                                                        <span class="mr-2 font-weight-bold" v-bind="attrs" v-on="on">{{ item.priority }}</span>
                                                    </template>
                                                    Priority
                                                </v-tooltip>
                                            </template>
                                            <template v-if="item.tag">
                                                <v-tooltip top>
                                                    <template #activator="{ on, attrs }">
                                                        <span class="mr-2 font-weight-bold" v-bind="attrs" v-on="on">{{ item.tag }}</span>
                                                    </template>
                                                    Tag
                                                </v-tooltip>
                                            </template>
                                            <template v-if="item.flags">
                                                <v-tooltip top>
                                                    <template #activator="{ on, attrs }">
                                                        <span class="mr-2 font-weight-bold" v-bind="attrs" v-on="on">{{ item.flags }}</span>
                                                    </template>
                                                    Flags
                                                </v-tooltip>
                                            </template>
                                            {{ item.value }}
                                        </td>
                                        <td class="text-left">
                                            {{ item.ttl }}
                                        </td>
                                        <td class="text-right">
                                            <v-btn icon color="black" disabled v-if="item.readonly">
                                                <font-awesome-icon :icon="['fas', 'lock']" style="font-size: 12px;" fixed-width/>
                                            </v-btn>
                                            <!-- <span class="pr-2 mx-1 text-center">
                                            <font-awesome-icon :icon="['fas', 'lock']" size="1x" style="font-size: 12px;" fixed-width/>
                                            </span> -->
                                            <v-menu offset-y left v-if="!item.readonly">
                                                <template v-slot:activator="{ on }">
                                                    <v-btn icon color="purple" v-on="on">
                                                        <font-awesome-icon :icon="['far', 'ellipsis-v']" style="font-size: 20px;" fixed-width/>
                                                    </v-btn>
                                                </template>
                                                <v-list class="ma-0 pa-0">
                                                    <v-list-item-group>
                                                    <!-- <v-list-item @click="openEditRecordDialog(item)">
                                                        <v-list-item-title>Edit</v-list-item-title>
                                                    </v-list-item> -->
                                                    <v-list-item @click="openDeleteRecordDialog(item)">
                                                        <v-list-item-title class="red--text">Delete</v-list-item-title>
                                                    </v-list-item>
                                                    </v-list-item-group>
                                                </v-list>
                                            </v-menu>
                                        </td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                    </v-card>
                    <!-- <template v-ifdev>
                    <p class="text-overline mt-12">Developer <font-awesome-icon :icon="['far', 'code']" class="grey--text"/></p>
                    <router-link :to="{ name: 'tbd' }">See TinyDNS format</router-link>
                    <v-expansion-panels tile>
                        <v-expansion-panel>
                            <v-expansion-panel-header>Developers</v-expansion-panel-header>
                            <v-expansion-panel-content class="text-start grey--text text--darken-2">
                                <p class="text-overline">Service name</p>
                                <p>asdfasdf</p>
                                <p class="text-overline">Service version</p>
                                <p>asdfasdfasdf</p>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    </template> -->
                </v-col>
            </v-row>
            <v-dialog v-model="createNewRecordDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="white">
                    <v-toolbar-title class="purple--text">Create new DNS record in {{ this.$route.params.domain }}</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5">
                <v-form class="px-2">
                    <v-row>
                    <v-select v-model="editableType" label="Type" :items="dnsRecordTypeChoices"></v-select>
                    </v-row>
                    <v-row>
                    <v-text-field v-model="editableKey" label="Key" ref="editableKeyInput"></v-text-field>
                    <v-text-field v-model="editablePriority" label="Priority" class="ml-4" v-if="editableType === 'MX'"></v-text-field>
                    <v-text-field v-model="editableTag" label="Tag" class="ml-4" v-if="editableType === 'CAA'"></v-text-field>
                    <v-text-field v-model="editableFlags" label="Flags" class="ml-4" v-if="editableType === 'CAA'"></v-text-field>
                    <v-text-field v-model="editableValue" label="Value" class="ml-4"></v-text-field>
                    </v-row>
                    <v-row>
                    <v-text-field v-model="editableRefresh" label="Refresh" style="max-width: 200px;"></v-text-field>
                    </v-row>
                </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="4" class="purple white--text" @click="createDnsRecord" :disabled="!isFormComplete">
                        <span>Create</span>
                    </v-btn>
                    <v-btn text color="grey" @click="createNewRecordDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
            <v-dialog v-model="deleteRecordDialog" max-width="600">
            <v-card tile elevation="4" class="pa-0" max-width="600">
                <v-toolbar short flat color="white">
                    <v-toolbar-title class="purple--text">Delete existing DNS record in {{ this.$route.params.domain }}</v-toolbar-title>
                </v-toolbar>
                <v-card-text class="px-5">
                    <p>Type: {{ editableType }}</p>
                    <p>Key: {{ editableKey }}</p>
                    <p>Value: {{ editableValue }}</p>
                    <p>Refresh: {{ editableRefresh }}</p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn elevation="4" class="purple white--text" @click="deleteDnsRecord">
                        <span>Delete</span>
                    </v-btn>
                    <v-btn text color="grey" @click="deleteRecordDialog = false">
                        <span>Cancel</span>
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
            </v-dialog>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
// import { mapState } from 'vuex';

export default {
    components: {
    },
    data: () => ({
        account: null,
        dnsRecordList: null,
        error: null,
        dnsRecordTypeChoices: [
            { value: 'A', text: 'IPv4 Address (A)' },
            { value: 'AAAA', text: 'IPv6 Address (AAAA)' },
            { value: 'TXT', text: 'Text (TXT)' },
            { value: 'SRV', text: 'Service (SRV)' },
            { value: 'CNAME', text: 'Canonical name (CNAME)' },
            { value: 'MX', text: 'Mail exchange (MX)' },
            { value: 'NS', text: 'Nameserver (NS)' },
            { value: 'CAA', text: 'Certificate authority (CAA)' },
        ],
        // create new record
        createNewRecordDialog: false,
        deleteRecordDialog: false,
        editableType: null,
        editableKey: null,
        editableValue: null,
        editablePriority: null,
        editableTag: null,
        editableFlags: null,
        editableRefresh: null,
    }),
    computed: {
        // ...mapState({
        //     session: (state) => state.session,
        // }),
        accountName() {
            return this.account?.name ?? 'Unknown';
        },
        isFormComplete() {
            if (this.editableType === 'MX') {
                return this.editableType && this.editableKey && this.editableValue && this.editableRefresh && this.editablePriority;
            }
            if (this.editableType === 'CAA') {
                return this.editableType && this.editableKey && this.editableValue && this.editableRefresh && this.editableTag && typeof this.editableFlags === 'string';
            }
            return this.editableType && this.editableKey && this.editableValue && this.editableRefresh;
        },
    },
    watch: {
        createNewRecordDialog(newValue) {
            if (newValue) {
                // clear the form
                this.editableType = 'A';
                this.editableKey = null;
                this.editableValue = null;
                this.editablePriority = null;
                this.editableTag = null;
                this.editableFlags = null;
                this.editableRefresh = 3600;
                setTimeout(() => { this.$nextTick(() => this.$refs.editableKeyInput.focus()); }, 1);
            }
        },
        editableType(newValue) {
            if (newValue === 'MX') {
                this.editablePriority = 10;
            }
            if (newValue === 'CAA') {
                this.editableTag = 'issue';
                this.editableFlags = '0';
            }
        },
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async loadDnsRecords() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadDnsRecords: true });
                const response = await this.$client.account(this.$route.params.accountId).domain.searchDnsRecord({ domain: this.$route.params.domain });
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (typeof response === 'object' && Array.isArray(response.list)) {
                    this.dnsRecordList = response.list;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load dns records', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadDnsRecords: false });
            }
        },
        async createDnsRecord() {
            try {
                this.error = false;
                this.$store.commit('loading', { createDnsRecord: true });
                const record = {
                    domain: this.$route.params.domain,
                    type: this.editableType,
                    key: this.editableKey,
                    value: this.editableValue,
                    ttl: this.editableRefresh,
                };
                if (record.type === 'MX') {
                    record.priority = this.editablePriority;
                }
                if (record.type === 'CAA') {
                    record.tag = this.editableTag;
                    record.flags = this.editableFlags;
                }
                const response = await this.$client.account(this.$route.params.accountId).domain.createDnsRecord(record);
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (typeof response === 'object' && response.isCreated) {
                    this.dnsRecordList.push(record);
                } else {
                    // TODO: show snackbar
                    this.error = true;
                }
            } catch (err) {
                console.error('failed to create dns record', err);
                this.error = true;
                // TODO: show snackbar
            } finally {
                this.$store.commit('loading', { createDnsRecord: false });
                this.createNewRecordDialog = false;
            }
        },
        openDeleteRecordDialog(item) {
            this.editableType = item.type;
            this.editableKey = item.key;
            this.editableValue = item.value;
            this.editablePriority = null;
            this.editableTag = null;
            this.editableFlags = null;
            this.editableRefresh = item.ttl;
            this.deleteRecordDialog = true;
        },
        async deleteDnsRecord() {
            try {
                this.$store.commit('loading', { deleteDnsRecord: true });
                const match = {
                    domain: this.$route.params.domain,
                    type: this.editableType,
                    key: this.editableKey,
                    value: this.editableValue,
                };
                const response = await this.$client.account(this.$route.params.accountId).domain.deleteDnsRecord(match);
                console.log(`deleteDnsRecord: response ${JSON.stringify(response)}`);
                if (typeof response === 'object' && response.isDeleted) {
                    this.loadDnsRecords();
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete DNS record' });
                }
            } catch (err) {
                console.error('failed to create dns record', err);
                this.error = true;
                // TODO: show snackbar
            } finally {
                this.$store.commit('loading', { deleteDnsRecord: false });
                this.deleteRecordDialog = false;
            }
        },
    },
    mounted() {
        this.loadAccount();
        this.loadDnsRecords();
    },
};
</script>
